import { render, staticRenderFns } from "./AdminOrderDetailGeneralInformationPart.vue?vue&type=template&id=a282b716&scoped=true&"
import script from "./AdminOrderDetailGeneralInformationPart.vue?vue&type=script&lang=ts&"
export * from "./AdminOrderDetailGeneralInformationPart.vue?vue&type=script&lang=ts&"
import style0 from "./AdminOrderDetailGeneralInformationPart.vue?vue&type=style&index=0&id=a282b716&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a282b716",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a282b716')) {
      api.createRecord('a282b716', component.options)
    } else {
      api.reload('a282b716', component.options)
    }
    module.hot.accept("./AdminOrderDetailGeneralInformationPart.vue?vue&type=template&id=a282b716&scoped=true&", function () {
      api.rerender('a282b716', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/orderDetailParts/AdminOrderDetailGeneralInformationPart.vue"
export default component.exports
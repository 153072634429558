var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardBody",
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { staticClass: "content-right", attrs: { md: "12" } },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "mr10",
                      attrs: {
                        color: "danger",
                        disabled:
                          _vm.showSpinnnerInCancelButton || !_vm.canBeCanceled
                      },
                      on: { click: _vm.handleCancel }
                    },
                    [
                      _vm.showSpinnnerInCancelButton
                        ? _c("CSpinner", { attrs: { size: "sm" } })
                        : _vm._e(),
                      _c("span", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "components.AdminOrderDetailButtons.cancel",
                            expression:
                              "'components.AdminOrderDetailButtons.cancel'"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("BookingButtonsModal", {
        attrs: {
          show: _vm.showCancelModal,
          title: _vm.$t("components.AdminOrderDetailButtons.titleCancel"),
          label: _vm.$t("components.AdminOrderDetailButtons.label"),
          btnLabel: _vm.$t("components.AdminOrderDetailButtons.cancel")
        },
        on: { onUpdateShow: _vm.confirmCancellation, onClose: _vm.onCloseModal }
      }),
      _c("Notification", {
        attrs: {
          text: _vm.getNotificationMessage,
          show: _vm.showSuccessNotification
        },
        on: {
          onClose: function() {
            return _vm.handleCloseSuccessNotification()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "components.HostOrderNotesPart.notes",
            expression: "'components.HostOrderNotesPart.notes'"
          }
        ]
      }),
      _c("CCardBody", [
        _c("div", [_vm._v("Order Notes:")]),
        _c("div", [_vm._v(_vm._s(_vm.order.orderNotes))]),
        _c("div", [_vm._v("Guest Notes:")]),
        _c("div", [_vm._v(_vm._s(_vm.order.guestNotes))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
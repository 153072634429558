var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.ready ? _c("CSpinner", { staticClass: "show-center" }) : _vm._e(),
      _vm.ready
        ? _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "12" } },
                [
                  _c("HostOrderDetailTitlePart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("AdminOrderDetailGeneralInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("AdminOrderNotesPart", { attrs: { order: _vm.order } }),
                  _c("OrderDetailPricePart", {
                    attrs: { order: _vm.order, showTax: true }
                  }),
                  _c("OrderDetailImportantInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("OrderDetailEventsPart", { attrs: { order: _vm.order } }),
                  _c("AdminOrderDetailButtons", {
                    attrs: { order: _vm.order },
                    on: { loadData: _vm.loadData }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }